<template>
	<div class="dropdown-filter">
        <div class="dropdown-wrapper">
            <span>
                {{ data.label }}
            </span>
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 7.5L10 12.5L15 7.5H5Z" fill="#13314E"/>
                </svg>
                <select class="dropdown-select" v-model="selectedFilter">
                    <option value="" disabled selected hidden>
                        {{ data.label }}
                    </option>
                    <option :value="option" v-for="(option, index) in data.filters" :key="`filter-${index}-${option.value}`">
                        {{ option }}
                    </option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
	
export default {
    name: "CSUtilsDropdownFilter",
    props: {
        data: Object,
    },
    data() {
        return {
            selectedFilter: ''
        }
    },
    methods: {
        emitValue() {
            this.$emit('filter-updated', { key: this.data.key, value: this.selectedFilter });
        }
    },
    watch: {
        selectedFilter() {
            this.emitValue();
        }
    }
};
</script>

<style lang="scss" scoped>
    .dropdown {
        &-wrapper {
            span {
                display: inline-block;
                font-size: 18px;
                margin-bottom: 15px;
                font-weight: 700;
                width: 100%;
            }

            div {
                position: relative;

                svg {
                    position: absolute;
                    right: 15px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    z-index: 2;
                }
            }

            select {
                opacity: 0.8;
                padding: 15px 40px  15px 15px;
                width: 100%;
                border-radius: 30px;
                position: relative;
                appearance: none;
                -moz-appearance: none;
                -webkit-appearance: none;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                &:active,
                &:focus,
                &:focus-within {
                    outline: none;
                }

                /* Remove built-in arrow */
                appearance: none;
                -moz-appearance: none;
                -webkit-appearance: none;
                &::-ms-expand {
                    display: none;
                }
            }
        }
    }
</style>